@import '../../assets/styles/helper-mixin.scss';

div.team {
  margin-top: 0px;


}

.team__inner {
  min-height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 30px;

  @include md-min {
    min-height: 60vh;
    position: relative;
    padding-top: 20px;
  }
}

h2.team__title {
  & span {
    font-size: 30px;
    color: var(--second-color);
    font-weight: bold;
    padding: 0 5px;
  }
}

.team__photo {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  min-height: 250px;
  margin: 20px 0;
  border: 2px solid white;
  width: 250px;

  @include sm-min {
    width: 350px;
    min-height: 350px;
  }

  @include md-min {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    min-width: 350px;
    min-height: 350px;
    padding: 0;
  }
}

.team__photo-first {
  background-image: url('../../assets/images/images-team/team-photo-1.jpg');
}

.team__photo-second {
  background-image: url('../../assets/images/images-team/team-photo-2.jpg');
}