@import '../../assets/styles/media-queries.scss';



.header {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  position: relative;
  background-image: url('../../assets/images/images-header/img-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: visible;
}

.header__title {
  color: var(--white-smoke-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: var(--second-color);

  @include md-max {
    width: 80%;
  }
}

.header__text-first {
  // border-top: 10px dotted var(--white-smoke-color);
  // border-bottom: 10px dotted var(--white-smoke-color);
  padding: 10px 0;
  font-size: 70px;
  text-align: center;
  letter-spacing: 8px;

  @include xs-max {
    font-size: 50px;
  }

  --size: 10px;

  &::before {
    content: "";
    display: block;
    height: var(--size);
    background: radial-gradient(at center, var(--white-smoke-color) 50%, transparent 50.5%);
    background-size: var(--size) var(--size);
    background-position: left center;
    background-repeat: space;
  }

  &::after {

    content: "";
    display: block;
    height: var(--size);
    background: radial-gradient(at center, var(--white-smoke-color) 50%, transparent 50.5%);
    background-size: var(--size) var(--size);
    background-position: left center;
    background-repeat: space;

  }
}

.header__text-second {
  font-size: 30px;
  text-align: center;
  letter-spacing: 8px;
  padding-top: 5px;

  @include xs-max {
    font-size: 20px;
  }
}