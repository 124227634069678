@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;700;800&family=Raleway:wght@100&display=swap');
@import '../../assets/styles/media-queries.scss';
@import '../../assets/styles/helper-mixin.scss';

:root {
  --main-color: #4d4b4a;
  --second-color: #f6ce4a;
  --white-color: #fff;
  --black-color: #000000;
  --gray-light-color: #b8b8b8;
  --white-smoke-color: #e8e8e8;
  --max-width: 1200px;
}

html {
  overflow-y: scroll;
  scroll-padding-top: 100px;
}

* {
  scroll-behavior: smooth;
  border: none;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  list-style: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
}

#root {
  height: 100vh;
  max-width: 100vw;
}

.section {
  margin-top: 50px;

}

.page__inner {
  @include content-width;
}

.title {
  position: relative;
  text-transform: uppercase;
  display: inline;
  font-size: 25px;
  letter-spacing: 2.2px;
  color: var(--main-color);

  &::after {
    position: absolute;
    left: 0;
    bottom: -7px;
    content: '';
    width: 100px;
    border-bottom: 2px solid var(--second-color);
  }

  @include xs-max {
    font-size: 20px;
  }
}

.scroll-to-top {
  right: 20px !important;
  border-radius: 50% !important;

  svg {
    fill: var(--main-color);
  }
}

.beatloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}