@import '../../assets/styles/media-queries.scss';

.slider {
  min-height: 30vh;
  margin-top: 50px;

  // @include xs {
  //   margin-top: 0;
  // }
}

.slider__img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 60vh;

  @include lg-min {
    min-height: 80vh;
  }
}

.slider__img-1 {
  background-image: url('../../assets/images/images-slider/slider-img-1.jpg');
}

.slider__img-2 {
  background-image: url('../../assets/images/images-slider/slider-img-2.jpg');
}

.slider__img-3 {
  background-image: url('../../assets/images/images-slider/slider-img-3.jpg');
}

.slider__img-4 {
  background-image: url('../../assets/images/images-slider/slider-img-4.jpg');
}

.slider__img-5 {
  background-image: url('../../assets/images/images-slider/slider-img-5.jpg');
}

.slider__img-6 {
  background-image: url('../../assets/images/images-slider/slider-img-6.jpg');
}

.slider__img-7 {
  background-image: url('../../assets/images/images-slider/slider-img-7.jpg');
}

.slider__img-8 {
  background-image: url('../../assets/images/images-slider/slider-img-8.jpg');
}

.slider__img-9 {
  background-image: url('../../assets/images/images-slider/slider-img-9.jpg');
}

.slider__img-10 {
  background-image: url('../../assets/images/images-slider/slider-img-10.jpg');
}

.slider__img-11 {
  background-image: url('../../assets/images/images-slider/slider-img-11.jpg');
}

.slider__img-12 {
  background-image: url('../../assets/images/images-slider/slider-img-12.jpg');
}

.slider__img-13 {
  background-image: url('../../assets/images/images-slider/slider-img-13.jpg');
}


.slider__img-15 {
  background-image: url('../../assets/images/images-slider/slider-img-15.jpg');

}

// carousel overwrite library //
.carousel .control-dots .dot {
  box-shadow: none;
  height: 15px !important;
  width: 15px !important;

  @include md-max {
    height: 12px !important;
    width: 12px !important;
    margin: 0 4px !important;

  }
}


.carousel .carousel-status {
  display: none;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
  content: '';
}


.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}


.carousel .control-next.control-arrow:before {
  border-left: 16px solid var(--main-color) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 16px solid var(--main-color) !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel-root.section {
  margin-top: 50px;
}