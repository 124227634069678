@import '../../assets/styles/media-queries.scss';

.menu {
  height: 100%;

  @include md-max() {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;

    background-color: var(--white-smoke-color);

    &.menu-is-open {
      visibility: visible;
      opacity: 1;
      flex-direction: column;
      width: 100%;
      height: 30vh;
      top: -50px;
    }
  }
}

.menu__inner {
  height: 100%;
}

.menu__list {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  @include md-max {
    flex-direction: column;
  }
}

.menu__item {
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--main-color);
  font-weight: 600;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  @include md-max {
    width: 100%;

    a {
      width: 100%;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    .menu__link {
      font-weight: bold;
      transition: 0.3s color;
    }
  }
}

.menu__link {
  color: var(--main-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  @include md-min {
    padding: 20px;
  }
}

.menu-desktop {
  @include md-max {
    display: none;
  }
}

.menu-mobile {
  @include md-min {
    display: none;
  }
}

.menu__mobile-item-bottom {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-items: center;


  @include xs-max {
    max-height: 40px;
  }

  .nav__meta-icon-facebook {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    padding: 10px;
    font-size: 12px;
  }

  svg {
    height: 12px;
  }

  .lang {
    font-size: 12px;
  }
}

.menu__item-lang {
  padding: 10px;
}