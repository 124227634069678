@import '../../assets/styles/media-queries.scss';

.nav {
  position: fixed;
  z-index: 1111;
  width: 100%;
  background-color: var(--white-smoke-color);
  max-width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.nav__logo-logo {
  display: block;
  background-image: url('../../assets//images/images-header/logo-2023.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-position: center;
  width: 105px;
  height: 60px;


  @include md-min {
    min-width: 150px;
    margin-left: 0;
    min-height: 85px;
    margin-right: 0;
  }
}

.nav__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white-smoke-color);

  @include md-max {
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    max-width: 100vw;
    margin: 10px 20px;
  }

  @include md-min {
    padding: 10px 20px;
  }
}

.nav__menu {
  width: 100%;

  // @include md-max {
  //   height: 0;
  // }
}

.nav__logo-burger {
  @include md-max {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.nav__meta-icon {
  color: var(--second-color);
}

.nav__meta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  border-left: 1px solid var(--main-color);

  @include md-max {
    display: none;
  }
}

.nav__meta-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--white-smoke-color);

  &:last-child {
    border-right: none;
  }

  @include md-max {
    padding: 0 10px;

    svg {
      padding: 10px 0;
    }
  }
}

.nav__meta-icon {
  padding: 0 20px;

  svg {
    height: 20px;

    color: var(--gray-light-color);

    @include md-max {
      height: 20px;
      padding: 0 7px;
    }
  }

  @include md-max {
    padding: 0;
  }
}

.nav__meta-text {
  text-align: right;

  @include md-max {
    font-size: 10px;
  }
}

.nav__meta-text {
  line-height: 1.2;
}

.nav__meta-email,
.nav__meta-address-street {
  color: var(--gray-light-color);
}

.nav__meta-icon-facebook svg,
.nav__meta-icon-language svg {
  color: var(--main-color);
  cursor: pointer;
}

.nav__meta-icon-facebook {
  @include md-max {
    display: none;
  }
}

.lang {
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: var(--main-color);
  background-color: transparent;
  font-size: 20px;

  @include md-max {
    font-size: 15px;
    padding: 10px 0;
  }

  .menu__list & {
    @include md-min {
      display: none;
    }
  }
}