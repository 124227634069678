@import '../../assets/styles/media-queries.scss';

.services {
  @include md-min {
    padding-top: 87px;
  }

  li {
    list-style: disc;
    padding: 5px 0;
    margin-left: 20px;
  }
}

.services__inner-text {
  text-align: left;
}

.services__items-icons {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 50px 0;
}

.services__service {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  svg {
    font-size: 50px;
    margin: 50px 0;
    color: var(--gray-light-color);

    @include sm-min {
      font-size: 75px;
    }
  }
}

.services__items-icons__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @include md-max {
    flex-direction: column;
  }
}

.services-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--main-color);
}
