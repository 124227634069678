@import "../../assets/styles/basic.scss";
@import "../../assets/styles/media-queries.scss";

.hamburger-react {
  display: none;
  z-index: 1000;
  margin-right: -8px !important;

  @include md-max {
    display: block;
    margin-left: auto;
    cursor: pointer;
  }
}