@import '../../assets/styles/media-queries.scss';

.form-container {
  background-color: var(--white-smoke-color);
  color: var(--main-color);
  padding: 20px 0;
}


.form__inner {
  @include md-max {
    padding: 0 20px;
  }
}

.form__inner--inner {
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  justify-content: center;

  @include md-max {
    flex-direction: column;
  }
}

h4.form__title {
  display: block;
  width: 100%;
  padding-left: 20px;
  color: var(--main-color);
  margin-top: 50px;

  &::after {
    margin-left: 20px;
  }

  @include md-min {
    max-width: var(--max-width);
    margin: 0 auto;
    padding-top: 40px;
  }
}

.form {
  width: 50%;
  display: flex;
  flex-direction: column;

  @include md-max {
    width: 100%;
    margin: 0 auto;
  }
}



.form-control {
  border: var(--color-white);
}

.btn {
  background-color: var(--color-white);
}

.input {
  height: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.label {
  padding: 5px 0;
}

.btn-submit {
  margin: 10px 0;
  width: 100px;
  min-height: 20px;
  padding: 5px 0;
  background: var(--white-color);
  color: var(--main-color);
  transition: 0.3s;

  &:hover:not(:disabled) {
    color: var(--white-color);
    background-color: var(--main-color);
  }
}

.text-area {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 100px;
}

.form__meta-email,
.form__meta-phone {
  display: block;
  cursor: pointer;
  color: var(--main-color);
}

.form__meta-email,
.form__meta-address-street {
  color: var(--main-color);
}

.form__meta-phone {
  // padding: 2px;
}

.form__meta-icon {
  padding: 0 20px;

  @include md-max {
    padding: 0;
  }

  svg {
    height: 30px;
    color: var(--main-color);

    @include md-max {
      height: 20px;
      padding-right: 10px;
    }
  }
}

.form__meta-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  &:nth-of-type(2),
  &:nth-of-type(3) {
    border-right: none;
    // padding-bottom: 0;

    // @include md-max {
    //   margin-bottom: 0px;
    // }

    @include md-min {
      flex-direction: row;
      margin-left: 0 !important;

    }

    @include sm-min {
      margin-left: 20px;
    }
  }
}

.form__meta {
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include md-max {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;

    @include sm-min {
      flex-direction: row;
    }
  }
}

.form__meta-text {
  @include md-max {
    font-size: 12px;
  }
}

.form-error {
  color: lightcoral;
  padding: 10px 0;
}

button:disabled {
  background: #fff;
  color: #ccc;
  cursor: no-drop;
}

.form-confirmation {
  font-weight: bold;
}

.form__meta-phone {
  padding: 5px 0;
}